<template>
  <div id="dom-location-map" style="height: 100%"></div>
  <div v-if="showMobileInfoWindow" class="mobile-info-window">
    <div class="close-button" @click.stop="hideMobileInfoWindow">
      <FeatherIcon type="x" />
    </div>
    <div class="list-item">
      <MessageItem :item="infoItem" :show-category="true" @click="drilldown(infoItem)" :parentName="'gmap'" />
    </div>
  </div>
</template>

<script>
/*eslint no-unused-vars: ["warn", { "args": "all" }]*/

import { computed, watch, toRefs, ref } from 'vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import { Loader } from '@googlemaps/js-api-loader';
import { GridAlgorithm, SuperClusterAlgorithm, MarkerClusterer } from '@googlemaps/markerclusterer';

import _ from 'lodash';
import api from '@/services/api';

import MessageItem from '@/components/Message/MessageItem.vue';

import positivePin from '@/assets/images/map-icon/marker-smile.png';
import negativePin from '@/assets/images/map-icon/marker-frown.png';
import neutralPin from '@/assets/images/map-icon/marker-meh.png';

import positiveMarkPin from '@/assets/images/map-icon/smile-num-mark.png';
import negativeMarkPin from '@/assets/images/map-icon/frown-num-mark.png';
import neutralMarkPin from '@/assets/images/map-icon/meh-num-mark.png';

export default {
  props: {
    useSearch: Boolean,
    searchObj: Object,
  },
  components: {
    MessageItem,
  },
  emits: ['boundChange'],
  setup(props, { emit }) {
    const { useSearch, searchObj } = toRefs(props);

    const showMobileInfoWindow = ref(false);
    const infoItem = ref({});
    const hideMobileInfoWindow = () => {
      showMobileInfoWindow.value = false;
    };

    const store = useStore();
    const filterCriteria = computed(() => store.getters['filter/filterCriteria']);

    const isSmallScreen = computed(() => store.getters['isSmallerMobileScreen']);

    const drilldown = (item) => {
      store.dispatch('seemore/show', item);
    };

    // Setup map
    const mapObj = {
      google: null,
      map: null,
      mapMarkers: [],
      clusterer: null,
      infoWindow: null,
      markerIdSet: new Set(),
      locationPolygon: null,
    };

    const mapOptions = {
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      center: { lat: 13.753234, lng: 100.500033 },
      minZoom: 6,
      maxZoom: 15,
      zoom: 11,
      styles: [
        {
          featureType: 'all',
          elementType: 'geometry.fill',
          stylers: [{ weight: '2.00' }],
        },
        {
          featureType: 'all',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#9c9c9c' }],
        },
        {
          featureType: 'all',
          elementType: 'labels.text',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [{ color: '#f2f2f2' }],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ffffff' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ffffff' }],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ color: '#eeeeee' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#7b7b7b' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ffffff' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [{ color: '#46bcec' }, { visibility: 'on' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [{ color: '#c8d7d4' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#070707' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ffffff' }],
        },
      ],
    };
    const loader = new Loader({
      apiKey: 'AIzaSyBWJ7W4tI-jLxQLHMK8lJHxhpQqWm2uWN8',
      version: 'weekly',
      libraries: ['places'],
    });

    const emitCurrentBound = () => {
      let mapBound = mapObj.map.getBounds();
      let ne = mapBound.getNorthEast();
      let sw = mapBound.getSouthWest();
      let bound = {};
      bound.latitudeMax = ne.lat();
      bound.latitudeMin = sw.lat();
      bound.longitudeMax = ne.lng();
      bound.longitudeMin = sw.lng();
      emit('boundChange', bound);
    };

    // Load map
    loader
      .load()
      .then((google) => {
        mapObj.google = google;
        mapObj.map = new google.maps.Map(document.getElementById('dom-location-map'), mapOptions);
        mapObj.google.maps.event.addListenerOnce(mapObj.map, 'idle', () => {
          // Init once
          getLocationData();
        });
        mapObj.map.addListener('dragend', () => {
          const gm = mapObj.infoWindow.getMap();
          if (gm) {
            // info window is open
          } else {
            resetClusterPopper(mapObj.map, true);
            // refreshMarker();
            emitCurrentBound();
          }
        });
        mapObj.map.addListener('zoom_changed', () => {
          resetClusterPopper(mapObj.map, true);
          // refreshMarker();
          emitCurrentBound();
        });
        mapObj.infoWindow = new mapObj.google.maps.InfoWindow();
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Cannot load google map configuration.',
        });
      });

    let clusterPopper = { rectangle: null, tempCluster: null, markers: [] };

    const clearOldClusterPopperData = () => {
      if (clusterPopper.markers) {
        for (let i in clusterPopper.markers) {
          clusterPopper.markers[i].setMap(null);
        }
        clusterPopper.markers = [];
      }
      if (clusterPopper.rectangle) {
        clusterPopper.rectangle.setMap(null);
        clusterPopper.rectangle = null;
      }
    };
    const resetClusterPopper = (map, noResume) => {
      if (clusterPopper.tempCluster) {
        clusterPopper.tempCluster.setMap(null);
        clusterPopper.tempCluster = null;
      }

      // if (noResume) {
      //   if (clusterPopper.tempCluster) {
      //     clusterPopper.tempCluster.setMap(null);
      //     clusterPopper.tempCluster = null;
      //     setupClusterer();
      //   }
      // } else {
      //   // Return temp cluster to map if had before continue
      //   // On click other cluster while open one
      //   if (clusterPopper.tempCluster) {
      //     console.log('RESUME PLS');
      //     clusterPopper.tempCluster.setMap(map);
      //     clusterPopper.tempCluster = null;
      //     setupClusterer();
      //   }
      // }
      // Clear old data
      clearOldClusterPopperData();
      setupClusterer();
    };
    const onClusterClick = (e, cluster, map) => {
      // console.log('Cluster click', e);

      const latPos = cluster.marker.position.lat();
      const longPos = cluster.marker.position.lng();
      // resetClusterPopper(mapObj.map);
      clearOldClusterPopperData();
      // give back old cluster
      if (clusterPopper.tempCluster) {
        const c = clusterPopper.tempCluster;
        c.setMap(map);
      }
      // Expand on current zoom > 13
      const currentZoomLevel = mapObj.map.getZoom();
      if (currentZoomLevel >= 13) {
        // TODO check this temp cluster?
        clusterPopper.tempCluster = cluster.marker;
        cluster.marker.setMap(null);
        let posCon = parseFloat(0.005);
        let defaultPos = JSON.parse(
          JSON.stringify({
            lat: latPos,
            lng: longPos,
          })
        );
        let posY = parseFloat(defaultPos.lat);
        let posX = parseFloat(defaultPos.lng);

        // Render rectangle
        //            |     Y+0.01
        // X+0.08  -     -  X-0.08
        //            |     Y+0.02
        // TODO? should we fit rectangle to fit the markers
        clusterPopper.rectangle = new mapObj.google.maps.Rectangle({
          strokeWeight: 0,
          fillColor: '#3371ff',
          fillOpacity: 0.15,
          // fillOpacity: 1,
          map,
          bounds: {
            north: posY + 0.01,
            south: posY - 0.008,
            east: posX + 0.008,
            west: posX - 0.008,
          },
          zIndex: 2000,
        });

        // Render it ... at max 9
        // | -x+y  +y   +y+x |
        // | -x     o    +x  |
        // | -x-y  -y   -y+x |
        // position [o] should be the origin of all
        for (let i in cluster.markers) {
          const m = cluster.markers[i];
          let lat = parseFloat(defaultPos.lat);
          let lng = parseFloat(defaultPos.lng);
          let iconData;
          if (m.type === 'positive') {
            iconData = positivePin;
          } else if (m.type === 'neutral') {
            iconData = neutralPin;
          } else if (m.type === 'negative') {
            iconData = negativePin;
          }
          if (i == 0) {
            // Do nothing
          } else if (i == 1) {
            // pos -x
            lng = lng - posCon;
          } else if (i == 2) {
            // pos +x
            lng = lng + posCon;
          } else if (i == 3) {
            // pos -x+y
            lat = lat + posCon;
            lng = lng - posCon;
          } else if (i == 4) {
            // pos +y
            lat = lat + posCon;
          } else if (i == 5) {
            // pos +x+y
            lat = lat + posCon;
            lng = lng + posCon;
          } else if (i == 6) {
            // pos -x-y
            lat = lat - posCon;
            lng = lng - posCon;
          } else if (i == 7) {
            // pos -y
            lat = lat - posCon;
          } else if (i == 8) {
            // pos +x-y
            lat = lat - posCon;
            lng = lng + posCon;
          } else {
            continue;
          }
          // console.log(i, m.type, { lat, lng });
          const mm = new mapObj.google.maps.Marker({
            position: new mapObj.google.maps.LatLng(lat, lng),
            map: mapObj.map,
            icon: iconData,
            zIndex: 2000 + +i,
          });
          mm.addListener('click', () => {
            // console.log('CCC', mm, m);
            getDetail(m.id, mm);
          });
          // mm.setMap(map);
          clusterPopper.markers.push(mm);
        }
        // console.log(clusterPopper.markers);
      } else {
        // Zoom it
        const latLngObj = new mapObj.google.maps.LatLng(latPos, longPos);
        // console.log(typeof longPos, typeof latPos, longPos, latPos);
        mapObj.map.setCenter(latLngObj);
        mapObj.map.setZoom(currentZoomLevel + 1);
      }
    };

    // TODO on other element click -> close cluster popper

    const getCustomClusterRenderer = (mapObj) => {
      return {
        render(cluster) {
          // Convert cluster -> google.maps.Marker
          // console.log(cluster, stats, mapObj.map.getZoom());

          let iconData = neutralMarkPin;
          let counter = [0, 0, 0];
          for (let m of cluster.markers) {
            if (m.type === 'positive') counter[0] += 1;
            else if (m.type === 'neutral') counter[1] += 1;
            else if (m.type === 'negative') counter[2] += 1;
          }
          let maxIndex = counter.indexOf(_.max(counter));
          if (maxIndex === 0) {
            iconData = positiveMarkPin;
          } else if (maxIndex === 1) {
            iconData = neutralMarkPin;
          } else if (maxIndex === 2) {
            iconData = negativeMarkPin;
          }

          let clusterCountText;
          if (cluster.count > 999) clusterCountText = '>1k';
          else clusterCountText = String(cluster.count);

          // create marker using svg icon
          return new mapObj.google.maps.Marker({
            position: cluster.position,
            icon: {
              url: iconData,
              scaledSize: new mapObj.google.maps.Size(48, 51),
              anchor: new mapObj.google.maps.Point(16, 52),
              labelOrigin: new mapObj.google.maps.Point(31, 17),
            },
            label: {
              text: clusterCountText,
              color: 'rgba(255,255,255,0.9)',
              fontSize: '12px',
            },
            // adjust zIndex to be above other markers
            zIndex: 1000 + cluster.count,
          });
        },
      };
    };

    const getDetail = (id, marker) => {
      // TODO is we need to check open cluster
      // no need to close and reset

      // resetClusterPopper(mapObj.map, true);
      // if (mapObj.infoWindow) {
      // }
      if (id.includes('twitter_')) {
        // Load tweet by id
        // console.log(id, marker);
        if (isSmallScreen.value) {
          const { lat, lng } = marker.position;
          const latLngObj = new mapObj.google.maps.LatLng(lat(), lng() + 0.001);
          console.log(lat(), lng());

          mapObj.map.setCenter(latLngObj);
          getMobileDetail(id);
        } else {
          let content = `<custom-info-window message-id="${id}"/>`;
          mapObj.infoWindow.setContent(content);
          mapObj.infoWindow.open(mapObj.map, marker);
        }

        // setTimeout(() => {
        //   mapObj.infoWindow.focus();
        // }, 400);
      }
    };

    const getMobileDetail = async (id) => {
      const result = await api.getFeedbyID(id).catch(() => {
        this.error = true;
      });
      infoItem.value = result.message;
      showMobileInfoWindow.value = true;
    };

    const setupClusterer = () => {
      if (mapObj.clusterer) {
        mapObj.clusterer.clearMarkers();
        mapObj.clusterer = null;
      }
      mapObj.clusterer = new MarkerClusterer({
        map: mapObj.map,
        markers: mapObj.mapMarkers,
        onClusterClick,
        algorithm: new SuperClusterAlgorithm({
          maxZoom: 18,
        }),
        // algorithm: new GridAlgorithm({
        //   maxDistance: 40000,
        //   maxZoom: 18,
        // }),
        renderer: getCustomClusterRenderer(mapObj),
        maxZoom: 18,
      });
    };

    const getLocationData = () => {
      // check is search
      const { resultData, location, centroidX, centroidY, options, boundList } = searchObj.value;
      if (options && options.isReset) {
        if (mapObj.map) {
          resetClusterPopper(mapObj.map, true);
        }
        // Do clear data
        clearMarkers();
        mapObj.markerIdSet = new Set();
        if (mapObj.clusterer) {
          mapObj.clusterer.clearMarkers();
          mapObj.clusterer = null;
        }
        if (mapObj.locationPolygon) {
          mapObj.map.data.forEach(function (feature) {
            // If you want, check here for some constraints.
            mapObj.map.data.remove(feature);
          });
        }
      }
      if (resultData) {
        let data = resultData;
        let targetData;
        for (let i = 0; i < data.length; i++) {
          let type;
          if (data[i].analytic.sentiment_score > 1) {
            data[i].markerSrc = positivePin;
            type = 'positive';
          } else if (data[i].analytic.sentiment_score < -1) {
            data[i].markerSrc = negativePin;
            type = 'negative';
          } else {
            data[i].markerSrc = neutralPin;
            type = 'neutral';
          }
          if (!mapObj.markerIdSet.has(data[i].info.id)) {
            const lat = parseFloat(data[i].raw_data.location.latitude.toFixed(5));
            const long = parseFloat(data[i].raw_data.location.longitude.toFixed(5));
            // console.log(data[i].info.id, lat, long);

            if (lat != 0 && long != 0) {
              if (!targetData) {
                targetData = [lat, long];
              }
              let marker = new mapObj.google.maps.Marker({
                position: new mapObj.google.maps.LatLng(lat, long),
                map: mapObj.map,
                icon: data[i].markerSrc,
                id: data[i].info.id,
                type,
              });
              marker.addListener('click', () => {
                getDetail(data[i].info.id, marker);
              });
              mapObj.mapMarkers.push(marker);
              mapObj.markerIdSet.add(data[i].info.id);
            }
          }
        }
        // if (mapObj.clusterer) {
        //   mapObj.clusterer.clearMarkers();
        //   mapObj.clusterer = null;
        // }
        if (!mapObj.clusterer) {
          setupClusterer();
        }
      }
      if (useSearch.value) {
        // draw bound on search / focus
        if (boundList) {
          // set first/end bound hotfix
          // const firstBound = boundList[0];
          // const lastBound = boundList[boundList.length - 1];
          // if (firstBound[0] !== lastBound[0] || firstBound[1] !== lastBound[1]) {
          //   boundList.push(firstBound);
          // }
          const geojson = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [
                    [
                      [0, 90],
                      [180, 90],
                      [180, -90],
                      [0, -90],
                      [-180, -90],
                      [-180, 0],
                      [-180, 90],
                      [0, 90],
                    ],
                  ],
                },
                properties: {
                  SD_NAME: 'OUT',
                },
              },
              {
                type: 'Feature',
                geometry: {
                  type: 'MultiPolygon',
                  coordinates: boundList,
                },
                properties: {
                  SD_NAME: 'GEO',
                },
              },
            ],
          };
          mapObj.locationPolygon = mapObj.map.data.addGeoJson(geojson);
          mapObj.map.data.setStyle(function (feature) {
            var SD_NAME = feature.getProperty('SD_NAME');
            if (SD_NAME == 'OUT') {
              return {
                fillColor: '#000',
                fillOpacity: 0.1,
              };
            }
            if (SD_NAME == 'GEO') {
              return {
                strokeColor: '#2C99FF',
                strokeOpacity: 0.5,
                fillColor: '#fff',
                fillOpacity: 0.5,
              };
            }
            return {};
          });
        }
        const latLngObj = new mapObj.google.maps.LatLng(centroidY, centroidX);
        mapObj.map.setCenter(latLngObj);

        if (location.amphoe && location.province) {
          mapObj.map.setZoom(11);
        } else {
          mapObj.map.setZoom(9);
        }
      }
    };

    const clearMarkers = () => {
      for (let i = 0; i < mapObj.mapMarkers.length; i++) {
        mapObj.mapMarkers[i].setMap(null);
      }
      // TODO? check for why need set length?
      // mapObj.mapMarkers.length = 0;
      mapObj.mapMarkers = [];
    };

    // TODO check for search obj - text or polygon
    watch(
      () => [useSearch.value, searchObj.value],
      () => {
        // resetClusterPopper(mapObj.map, true);
        // clearMarkers();
        // mapObj.markerIdSet = new Set();
        // mapObj.clusterer.clearMarkers();
        // mapObj.clusterer = null;
        // console.log('SEARCH', useSearch.value, searchObj.value);
        setTimeout(() => {
          getLocationData();
        }, 100);
      }
    );

    const refreshMarker = _.debounce(() => {
      if (!useSearch.value) {
        resetClusterPopper(mapObj.map, true);
        // clearMarkers();
        // mapObj.clusterer.clearMarkers();
        setTimeout(() => {
          getLocationData();
        }, 100);
      }
    }, 500);

    // Watch data - re-render marker
    watch(
      () => filterCriteria.value,
      () => refreshMarker()
    );

    return {
      showMobileInfoWindow,
      hideMobileInfoWindow,
      infoItem,
      drilldown,
    };
  },
};
</script>

<style lang="scss" scoped>
.dom-location-map {
  min-height: 500px;
  height: 100%;
}
.mobile-info-window {
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 150px;
  z-index: 100;
  .close-button {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #999;
    z-index: 101;
  }
  .list-item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 3px 5px 0px #b6b6b6;
    z-index: 100;
  }
}
</style>
